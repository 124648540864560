import React from 'react'

import './navbar.css'
const Navbar = () => {

  

  return (
    <nav>
        <div className='logo'>
            <span>
              <a href='/'>              
              Çınar Ticaret
              </a>
              </span>
        </div>
        <div className='links'>
            <ul>
                <li><a href='/'>Anasayfa</a></li>

                <li><a href='/yedek-parca-listesi/'>Parça Listesi</a></li>

            </ul>
        </div>
    </nav>
  )
}

export default Navbar