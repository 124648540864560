import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './components/HomePage';
import Navbar from './components/Navbar'; 
import Login from './components/Login';
import SpareTypeCreate from './components/SpareTypeCreate';
import SpareTypeList from './components/SpareTypeList';
import SparePartCreate from './components/SparePartCreate';
import SparePartList from './components/SparePartList';
import SparePartDetail from './components/SparePartDetail';

import SparePartListFilter from './components/SparePartListFilter';
import SparePartEdit from './components/SparePartEdit';

import About from './components/About';
import Contact from './components/Contact';
function Page() {
  return (
    <div>
      <BrowserRouter>        
        <Navbar/>
        <Routes>
          <Route path="/" element={<HomePage />} />  
          <Route path="/giris/" element={<Login />} />  
          <Route path="/yedek-parca-turu-ekle/" element={<SpareTypeCreate />} />  
          <Route path="/yedek-parca-turleri/" element={<SpareTypeList />} />  
          <Route path="/yedek-parca-ekle/" element={<SparePartCreate />} />  
          <Route path="/yedek-parca-listesi/" element={<SparePartList />} />  
          <Route path="/yedek-parca-listesi/:slug" element={<SparePartListFilter />} />  
          <Route path="/yedek-parca-detay/:id/" element={<SparePartDetail />} />  
          <Route path="/hakkimizda/" element={<About />} />  
          <Route path="/iletisim/" element={<Contact />} />  

          <Route path="/yedek-parca-duzenle/:id" element={<SparePartEdit />} />  

        </Routes>        
      </BrowserRouter>

  </div>



  )
}


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Page/>);





