import React,{useEffect,useState} from 'react';
import './sparepartcreate.css';
import axios from 'axios';

const SparePartCreate = () => {
    const [spareType,setSpareType] = useState([])
    const [selectType,setSelectType] = useState()
    const [name,setName] = useState()
    const [brand,setBrand] = useState()
    const [product_status,setProduct_status] = useState()
    const [image,setImage] = useState()
    const [price,setPrice] = useState(0)
    const [price2,setPrice2] = useState(0)
    const [description,setDescription] = useState()
    const [piece,setPiece] = useState()
    const [info,setInfo] = useState(false)
        
    useEffect(()=>{
        fetch(`https://cinarsite.pythonanywhere.com/posts/spare-type-list/`)
        .then(res=>res.json())
        .then(response=>setSpareType(response))
        .catch(error=>console.log(error))
    },[])

    const HandleSubmit=(e)=>{
        e.preventDefault();
        const config = {
            headers:{
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }
          };
        const data = new FormData();
        data.append('sparetype',selectType)
        data.append('name',name)
        data.append('brand',brand)
        data.append('product_status',product_status)
        data.append('image',image)
        data.append('price',price)
        data.append('price2',price2)
        data.append('description',description)
        data.append('piece',piece)
        axios.post(`https://cinarsite.pythonanywhere.com/posts/spare-part-create/`,data,config)

        .then(response=>{setInfo(true)})
        .catch(error=>console.log(error.response))
    }
    return (
    <div className='spare-part-create'>
        {
            info === true && <p>Başarıyla Kaydedildi</p>
        }
        <h2>Yedek Parça Ekle</h2>
        <div className='spare-type-list'>
            <form onSubmit={HandleSubmit} encType='multipart/form-data'>
            <div className='inputs'>
                <select onChange={e=>setSelectType(e.target.value)}>
                    <option>Tür Seçiniz</option>
                    {spareType.map(sparetype =>
                    <option required key={sparetype.id} value={sparetype.id}>{sparetype.caption}</option>
                    )}
                  </select>
            </div>
            <div className='inputs'>
                <input type='text' placeholder='Parçanın Adı' required onChange={(e)=>setName(e.target.value)}/>
            </div>
            <div className='inputs'>
                <input type='text' placeholder='Markası' required onChange={(e)=>setBrand(e.target.value)}/>
            </div>
                <p style={{textAlign:'center',fontSize:'.7rem'}}>Fiyat Yazı ile birlikte yazılacaksa &#8595;</p>
            <div className='inputs'>
                <input type='text' placeholder='TL' onChange={(e)=>setPrice(e.target.value)}/>
            </div>
            <p style={{textAlign:'center',fontSize:'.7rem'}}>Fiyat Sayı ile yazılacaksa &#8595;</p>

            <div className='inputs'>
                <input type='number' defaultValue={0} onChange={(e)=>setPrice2(e.target.value)}/>
            </div>
            <div className='inputs'>
                <input type='text' placeholder='Adedi' required onChange={(e)=>setPiece(e.target.value)}/>
            </div>
            <div className='inputs'>
                <select onChange={e=>setProduct_status(e.target.value)}>
                    <option key={1} value='Stokta Var'>Stokta Var</option>
                    <option key={2} value='Stokta Yok'>Stokta Yok</option>
                </select>
            </div>
            <div className='inputs'>
                <input type='file' placeholder='Fotoğraf' onChange={(e)=>setImage(e.target.files[0])}/>
            </div>
            <div className='inputs'>
                <input type='text' placeholder='Açıklama' onChange={(e)=>setDescription(e.target.value)}/>
            </div>
            <div className='sparepart-button'>
                <button>Kaydet</button>
            </div>
            </form>

        </div>
    </div>
  )
}

export default SparePartCreate