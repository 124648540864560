import React,{useState} from 'react';
import './sparetypecreate.css';

const SpareTypeCreate = () => {
  const [caption,setCaption]=useState()
  const [info,setInfo] = useState(false)
  const HandleSubmit = (e) =>{
    e.preventDefault();
    const data = new FormData();
    data.set('caption',caption)
    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-type-create/`,{
      method : 'POST',
      headers : {'Content-type':'application/json'},
      body:JSON.stringify({
        caption:caption
      })
    })
    .then(res=>res.json())
    .then(response=>{setInfo(true)})
    .catch(error=>console.log(error))
  }
  
  return (
    <div className='type-container'>
      {info === true &&
        <p>Başarıyla Kaydedildi</p>}
      <h2>Yedek Parça Türü</h2>
      <form onSubmit={HandleSubmit}>
      <div className='type-form'>
        <input type='text' placeholder='Yedek Parça Türü' required onChange={(e)=>setCaption(e.target.value)}/>
      </div>
      <div className='type-button'>
        <button type='submit'>Kaydet</button>
      </div>
      </form>        

    </div>
  )
}

export default SpareTypeCreate