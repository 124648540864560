import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
const SparePartListFilter = () => {
  const [sparepart,setSparePart] = useState([])
  const [loading,setLoading]=useState(true)
  const {slug} = useParams()
  useEffect(()=>{
    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-part-list-filter/${slug}`)
    .then(res=>res.json())
    .then(response=>{setLoading(false);setSparePart(response)})
    .catch(error=>console.log(error.response))
},[slug])
    return (
      <>
      {loading === true &&
      <div className='loading-page'>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
      }
  
    <div className='sparepart-container'>
        <h2 style={{fontSize:'2rem'}}>{slug}</h2>
        <div className='sparepart-list'>
        {sparepart.map((sparepart,index)=>
          <div key={index} className='sparepart-detail'>
            <div className='sparepart-header'>
              <a href={`/yedek-parca-detay/${sparepart.id}`}>            
              <h3>{sparepart.name}</h3>
              </a>          
            </div>
            <div className='sparepart-image'>
            <img src={sparepart.image} />
          </div>

          <div className='sparepart-info'>
            <p>Markası</p>
            <p>{sparepart.brand}</p>
          </div>
          <div className='sparepart-info'>
            <p>Açıklama</p>
            <p>{sparepart.description}</p>          
          </div>
  
          </div>
          )}
        </div>
      </div>
      </>


  )
}

export default SparePartListFilter