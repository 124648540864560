import React,{useState,useEffect} from 'react'
import './sparetypelist.css';

const SpareTypeList = () => {
  const [sparetype,setSpareType] = useState([])
  const [loading,setLoading] = useState(true)

  useEffect(()=>{
    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-type-list/`)
    .then(res=>res.json())
    .then(response=>{setSpareType(response);setLoading(false)})
    .catch(error=>console.log(error))
  },[])


  return (
    <>
    {loading === true &&
    <div className='loading-page'>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    }

  <div className='spare-type-container'>
      <h2>Yedek Parça Türleri</h2>
      <div className='spare-type-list'>
        {sparetype.map((sparetype,index)=>
      <a key={index} href={`/yedek-parca-listesi/${sparetype.slug}`}>
        <div  className='spare-type'>
            <h3>{index + 1} - {sparetype.caption}</h3>
          </div>
      </a>
        )}
      </div>
    </div>
  </>
  )
}

export default SpareTypeList