import React,{useEffect,useState} from 'react'
import './sparepartlist.css';

const SparePartList = () => {
  const [sparepart,setSparePart] = useState([])
  const [searchItem, setSearchItem] = useState('');
  const [filtered,setFiltered] = useState([])  
  const [loading,setLoading] = useState(true)

  
  
  useEffect(()=>{
    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-part-list/`)
    .then(res=>res.json())
    .then(response=>{setSparePart(response);setLoading(false)})
  },[])
  
  useEffect(() => {
    setFiltered(
      sparepart.filter((sparepart) =>
      sparepart.name.toLowerCase().includes(searchItem.toLowerCase())
      )
    );

  }, [searchItem, sparepart]);

  

  return (
    <>
    {loading === true &&
    <div className='loading-page'>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    }

  <div className='sparepart-container'>
      <h2>Yedek Parça Listesi</h2>
      <div className='search-input'>
        <input placeholder='Ara...' onChange={(e) =>{setSearchItem(e.target.value)}}/>
      </div >
      <div className='sparepart-list'>
      {filtered.map((sparepart,index)=>
        <div key={index} className='sparepart-detail'>
          <div className='sparepart-header'>
            <a href={`/yedek-parca-detay/${sparepart.id}`}>            
            <h3>{sparepart.name}</h3>
            </a>          
          </div>
          <a href={`/yedek-parca-detay/${sparepart.id}`}>            
          <div className='sparepart-image'>
          <img src={sparepart.image} />
        </div>
        </a>
        <div className='sparepart-info'>
          <p>Markası</p>
          <p>{sparepart.brand}</p>
        </div>
        <div className='sparepart-info'>
          <p>Açıklama</p>
          <p>{sparepart.description}</p>          
        </div>

        </div>
        )}
      </div>
    </div>
    </>
  )
}

export default SparePartList