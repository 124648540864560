import React from 'react'

const About = () => {
  return (
    <div className='home-container'>
        <h2 style={{textAlign:'center',marginBottom:'30px'}}>Hakkımızda</h2>
        <div className='container'>

        <p style={{textAlign:'justify',fontSize:'1.3rem'}}>Öncül amacımız severek ve büyük bir heyecanla yaptığımız işimiz ile müşterilerimizin ihtiyaçlarına en iyi şekilde cevap verip aynı zamanda müşterilerimizin memnuniyetini sağlamaktır.
        Sektörü içerisinde sürekli kendimizi yenileyerek müşterilerimize her zaman daha kaliteli hizmet verme çabası içinde olup müşteri memnuniyetini de önemsemekteyiz.
        Firmamızın en değerli parçası olan siz değerli müşterilerimizin hoşnut olabilmesi için dünya standartlarında modern uygulamaları getirmekten çekinmiyoruz.
        Sektöründe lider bir firma olmayı benimseyen firmamız, firmalar ve müşterilerine yenilikçi, dürüst, kaliteli hizmet vermeyi ilke edinmiştir.
        Yeniliklere açık bir şekilde performans gösteren kadromuz sayesinde en yeni teknolojiler bile oldukça kısa bir süre içerisinde hayat buluyor. Tüm bu kaliteli uygulamaları gerçekleştirirken firmamızın daha geniş bir kitleye ulaşabilmesi için çaba gösteriyoruz.
        </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.9086192912864!2d27.165275415193534!3d38.397361879650845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd92fdc34286f%3A0xeadc203beb1d7913!2s%C3%87%C4%B1nar%20Ti%C3%A7aret%20kombi%20yedek%20par%C3%A7a%20ve%20kombi%20kart%20tamiri!5e0!3m2!1str!2str!4v1673878370150!5m2!1str!2str"  style={{height:'400px', width:'100%'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
   
        </div>

    </div>
  )
}

export default About