import React from 'react';
import './homepage.css';
import slider from '../media/slider.jpg'
import slider1 from '../media/slider1.jpeg'
import slider2 from '../media/slider2.jpeg'


const HomePage = () => {
  return (
<>
<marquee direction="left" style={{fontSize:'1.2rem',color:'#2d545e',fontWeight:'900'}}>ÇINAR TİCARET İLETİŞİM İÇİN 0534 745 34 69</marquee>

<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src={slider} style={{padding:'20px',maxHeight:'500px',objectFit:'cover'}} className="d-block w-100" alt="..."/>
      </div>
      <div className="carousel-item">
        <img src={slider1} style={{padding:'20px',maxHeight:'500px',objectFit:'cover'}} className="d-block w-100" alt="..."/>
      </div>
      <div className="carousel-item">
        <img src={slider2} style={{padding:'20px',maxHeight:'500px',objectFit:'cover'}} className="d-block w-100" alt="..."/>
      </div>

    </div>
   <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </button>
  </div>
        
  
<div className='home-container'>
      

      <a href='/yedek-parca-listesi/'>
        <div className='box'>
          <h2>Parça Listesi</h2>
        </div>
      </a>


      <a href='/yedek-parca-turleri/'>
        <div className='box'>
          <h2>Parça Türleri </h2>
        </div>
      </a>

      <a href='/hakkimizda/'>
        <div className='box'>
          <h2>Hakkımızda </h2>
        </div>
      </a>
      <a href='/iletisim/'>
        <div className='box'>
          <h2>İletişim </h2>
        </div>
      </a>
{localStorage.getItem('token') &&
    <>
      <a href='/yedek-parca-turu-ekle/'>
        <div className='box'>
          <h2>Tür Ekle </h2>
        </div>
      </a>
      <a href='/yedek-parca-ekle/'>
        <div className='box'>
          <h2>Parça Ekle </h2>
        </div>
      </a>
    </>  
    }

    </div>
    </>
  )
}

export default HomePage