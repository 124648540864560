import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import './login.css';
const Login = () => {
  const [username,setUsername]=useState('')
  const [password,setPassword]=useState('')
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('token') !== null && localStorage.getItem('token')!== 'undefined') {
      navigate('/');
    } else {
      setLoading(false);
    }
  }, [localStorage.getItem('token')]);


  const HandleSubmit=(e)=>{
    e.preventDefault();
    const data = new FormData();
    data.set('username',username)
    data.set('password',password)
    
    fetch(`https://cinarsite.pythonanywhere.com/users/login/`,{
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({ username: username,
      password:password,
    })
    })
    .then(res=>res.json())
    .then(response=>{localStorage.setItem('token',response.token);setError(response.error);
  console.log(response);localStorage.setItem('status',response.status)})
    .catch(error=>console.log(error))
  }
  return (
    <div className='login-div'>
          <h2 className='login-header'>Giriş Yap</h2>
        {error && <p className='login-error'>{error}</p>}
        <div className='login-container'>    
        <form onSubmit={HandleSubmit}>
            <div className='login-input'>
            <input type='text' required onChange={(e)=>setUsername(e.target.value)} placeholder='Kullanıcı Adı' />                
            </div>
            <div className='login-input'>
            <input type='password' required onChange={(e)=>setPassword(e.target.value)} placeholder='Parola' />
            </div>
            <div className='login-button'>
            <button type='submit'>Giriş</button>
            </div>
        </form>
        </div>
    
    </div>
  )
}

export default Login