import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import './sparepartedit.css';

const SparePartEdit = () => {
    const [sparepart,setSparePart] = useState([])
    const [price,setPrice] = useState()
    const [price2,setPrice2] = useState()
    const [piece,setPiece] = useState()
    const [description,setDescription] = useState()
    const [name,setName] = useState()
    const [brand,setBrand] = useState()
    const {id} = useParams()
    const [info,setInfo] = useState(false)
    
  useEffect(()=>{
    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-part-edit/${id}/`)
    .then(res=>res.json())
    .then(response=>{console.log(response);
        setSparePart(response);        
        setPiece(response.piece);
        setName(response.name);
        setDescription(response.description)
        setBrand(response.brand)
        
        setPrice2(response.price2);
        setPrice(response.price);


        
    })
  },[])


  const HandleSubmit = (e)=>{
    e.preventDefault();
    const data = new FormData();
    data.set('name',name)
    data.set('brand',brand)
    data.set('price2',price2)
    data.set('price',price)
    data.set('piece',piece)
    data.set('description',description)

    fetch(`https://cinarsite.pythonanywhere.com/posts/spare-part-edit/${id}/`,{
        method : 'PATCH',
        headers :{'Content-type':'application/json'}, 
        body:JSON.stringify({
            name:name,
            brand:brand,
            price : price,
            price2 : price2,
            piece:piece,
            description:description
        })

    })
    .then(res=>res.json())
    .then(response=>{
        setSparePart(response);
        setPrice2(response.price2);
        setPrice(response.price);
        setPiece(response.piece);
        setName(response.name);
        setDescription(response.description)
        setBrand(response.brand)
        setInfo(true)

    })
    .catch(error=>console.log(error))
}
    return (
    <div className='sparepart-edit-container'>
       { info === true &&
            <p>Başarıyla Kaydedildi</p>
        }
 
        <h2>Yedek Parça Düzenle</h2>
        <form onSubmit={HandleSubmit}>
            <div className='sparepart-edit'>
                <p>İsmi:</p>
                <input type='text' onChange={(e)=>setName(e.target.value)} defaultValue={name}/>
            </div>
            <div className='sparepart-edit'>
                <p>Fiyatı:</p>
                {
                    sparepart.price == 0 ?
                    <input type='number' onChange={(e)=>setPrice2(e.target.value)} defaultValue={price2}/>
                    :
                    <input type='text' onChange={(e)=>setPrice(e.target.value)} defaultValue={price}/>
                }
            </div>

            <div className='sparepart-edit'>
                <p>Stok:</p>
                <input type='number' onChange={(e)=>setPiece(e.target.value)} defaultValue={piece}/>
            </div>
            <div className='sparepart-edit'>
                <p>Marka:</p>
                <input type='text' onChange={(e)=>setBrand(e.target.value)} defaultValue={brand}/>
            </div>
            <div className='sparepart-edit'>
                <p>Açıklama:</p>
                <input type='text' onChange={(e)=>setDescription(e.target.value)} defaultValue={description}/>
            </div>
            <div className='sparepart-edit-button'>
                <button type='submit'>Kaydet</button>
            </div>
        </form>
    </div>
  )
}

export default SparePartEdit