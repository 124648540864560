import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import './sparepartdetail.css'

const SparePartDetail = () => {
    const {id} = useParams()
    const [sparepart,setSparePart] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        fetch(`https://cinarsite.pythonanywhere.com/posts/spare-part-detail/${id}/`)
        .then(res=>res.json())
        .then(response=>{setSparePart(response);console.log(response);setLoading(false)})
        .catch(error=>console.log(error.response))
    },[])
    return (
    <>
        {loading === true &&
    <div className='loading-page'>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    }

    <div className='sparepart-detail-container'>
        <h2>{sparepart.name}</h2>
        {localStorage.getItem('token')&& <a href={`/yedek-parca-duzenle/${sparepart.id}`}>Düzenle</a>}
        
        <div className='sparepart-detail'>


            <div className='sparepart-hedaer'>
                <h3>Markası</h3>        
                <h3>{sparepart.brand}</h3>        
            </div>
            <div className='sparepart-hedaer'>                
                <h3>Türü</h3>        
                <h3>{sparepart.sparetype}</h3>        
            </div>

            <div className='sparepart-image'>
                <img src={sparepart.image} />
            </div>
        </div>
    </div>
    </>

  )
}

export default SparePartDetail