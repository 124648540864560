import React from 'react'

const Contact = () => {
  return (
    <div className='home-container'>
        <h2 style={{textAlign:'center',marginBottom:'30px',padding:'10px'}}>İLETİŞİM</h2>
    
        <div className='container'>
            <h3>YETKİLİ KİŞİ : MÜMTAZ FURKAN İKİZLER </h3>
            <h3>E-MAİL : m.furkanikizler@gmail.com</h3>
            <h3>cinarkombiyedekparcakarttamiri@gmail.com</h3>
            <h3>GSM: 0534 745 34 69 </h3>
            <h3>ADRES BİLGİLERİ : UFUK MAH. SEYFİ DEMİRSOY CADDESİ NO : 58/A BUCA / İZMİR </h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.9086192912864!2d27.165275415193534!3d38.397361879650845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd92fdc34286f%3A0xeadc203beb1d7913!2s%C3%87%C4%B1nar%20Ti%C3%A7aret%20kombi%20yedek%20par%C3%A7a%20ve%20kombi%20kart%20tamiri!5e0!3m2!1str!2str!4v1673878370150!5m2!1str!2str"  style={{height:'400px', width:'100%'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        </div>
    </div>
  )
}

export default Contact